@mixin hostfill() {
  :host {
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-direction: column;
  }
}

@mixin row-centered() {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center flex-start;
    align-items: center;
}

@mixin row-align-top() {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center flex-start;
    align-items: flex-start;
}

@mixin fill-flex-no-overflow() {
  flex: 1;
  overflow: hidden;
  display: flex;
  height: 100%;
  flex-direction: column;
}

@mixin fill-overflow {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    display: flex;
}

@mixin box-hover {
  &:hover {
    cursor: pointer;
    background-color: var(--list-hover-background);
  }
}

@mixin single-form-page {
  @include hostfill();

  .form-wrapper {
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

