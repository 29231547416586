.xng-breadcrumb-root {
  padding: 4px;
  display: inline-block;
  margin-left: 8px !important;
  opacity: 0.7;
}

.xng-breadcrumb-separator {
  padding: 0 4px;
}

.xng-breadcrumb-item {
  &:hover {
    opacity: 1.0;
  }
}

