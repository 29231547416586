.status.foreground {
  &.passed {
    color: var(--success);
  }
  &.flake {
    color: var(--flake);
  }
  &.failed, &.timeout {
    color: var(--failed);
  }
  &.cancelled {
    color: var(--cancelled);
  }
  &.pending, &.started, &.building, &.running {
    color: var(--primary);
  }
}

.status.background {
  color: white;
  &.passed {
    background-color: var(--success);
  }
  &.failed, &.timeout {
    background-color: var(--failed);
  }
  &.cancelled {
    background-color: var(--cancelled);
  }
  &.pending, &.started, &.building, &.running {
    background-color: var(--primary);
  }
}

.mat-mdc-progress-bar.hide-dots .mdc-linear-progress__buffer-dots {
  display: none;
}
