// importing core styling file

mat-paginator.hide-range-label .mat-mdc-paginator-range-label {
  display: none;
}

.autocomplete-field {
  .clear {
    position: absolute;
    right: 0;
    opacity: 0.5;
    top: 16px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  i.caret {
    position: absolute;
    right: -6px;
    top: 14px;
  }
}

.mat-mdc-form-field.mat-focused.mat-primary i.caret {
  color: var(--primary);
}

.no-subscript .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-select.white-caret .mat-mdc-select-trigger .mat-mdc-select-arrow {
    color: white;
}

.vertical-form {
  flex-direction: column;
  display: flex;
  row-gap: 8px;
}


mat-form-field {
  &.full-width {
    width: 100%;
  }
  &.medium {
    max-width: 20rem;
  }
}
