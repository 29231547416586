table.simple {
  border-collapse: collapse;
  td, th {
    padding: 16px;
    text-align: left;

    border-bottom-color: var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
    border-bottom-width: var(--mat-table-row-item-outline-width, 1px);
    border-bottom-style: solid;
    letter-spacing: var(--mat-table-header-headline-tracking);
    font-weight: inherit;
    line-height: inherit;
  }

  thead tr {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    height: var(--mat-table-header-container-height, 56px);
    color: var(--mat-table-header-headline-color, rgba(0, 0, 0, 0.87));
    font-family: var(--mat-table-header-headline-font, Roboto, sans-serif);
    line-height: var(--mat-table-header-headline-line-height);
    font-size: var(--mat-table-header-headline-size, 14px);
    font-weight: var(--mat-table-header-headline-weight, 500);
  }

  tbody tr {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--mat-table-row-item-label-text-font, Roboto, sans-serif);
    line-height: var(--mat-table-row-item-label-text-line-height);
    font-size: var(--mat-table-row-item-label-text-size, 14px);
    font-weight: var(--mat-table-row-item-label-text-weight);
  }
  background-color: white;
  tr {
    border-bottom: 1px solid var(--border-color);
  }

}
