.typeahead {
  .search-box {
    position: relative;
    fa-icon.search, button.clear-button {
      right: 0.75rem;
    }
    fa-icon.search {
      pointer-events: none;
      font-size: 20px;
      top: 10%;
      margin-top: 0;
    }
    input {
      padding-right: 2rem;
      z-index: 1;
    }
    button.clear-button {
      cursor: pointer;
      z-index: 2;
      margin: 0;
      padding: 0;
      top: 20%;
    }
    button.clear-button, fa-icon.search, fa-icon.spinner {
      position: absolute;
      width: 1rem;
      border: 0;
      z-index: 2;
    }
    fa-icon.spinner {
      right: 40px;
      top: 50%;
      font-size: 20px;
      margin-top: -12px;
      width: auto;
      height: auto;
    }
  }
}

.cdk-overlay-pane > .dropdown-menu {
  position: absolute;
  z-index: 7000;
  min-width: 12rem;
  max-width: 100%;
  width: 100%;

  margin-top: .125rem;
  margin-bottom: .125rem;
  border: 1px solid var(--border-color);
  border-radius: .25rem;
  padding: .25rem 0;
  background: #fff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .16);
  max-height: calc(((1rem * 1.5) + 1rem) * 5);
  overflow-y: auto;

  font-size: 14px;

  .no-match {
    text-align: center;
    padding: 4px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
      margin: 0;
      padding: 0;
    }

    [role="option"] {
      padding: 8px;
      cursor: pointer;

      &:hover, &.hasFocus {
        background-color: var(--list-hover-background);
        opacity: var(--list-hover-background-opacity);
      }
    }
  }
}
