@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);
  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, "primary");

  $warn-palette: map.get($color-config, "warn");

  .nav-bar {
    background-color: mat.get-color-from-palette($primary-palette, 500);
    color: mat.get-contrast-color-from-palette($primary-palette, 500);
  }

  .nav-menu-item.mat-mdc-button:not(:disabled) {
    color: mat.get-contrast-color-from-palette($primary-palette, 900);
    &.active {
      background-color: mat.get-color-from-palette($primary-palette, 900);
      color: mat.get-contrast-color-from-palette($primary-palette, 900);
      font-weight: 500;
    }
    fa-icon {
      padding-right: 8px;
    }
  }

  fa-icon.warn {
    color: mat.get-color-from-palette($warn-palette, 500);
  }
}
