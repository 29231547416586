ul.single-selection-list {
  list-style: none;
  margin: 8px;
  padding: 0;
  li:not(:last-of-type) {
    margin-bottom: 8px;
  }
  li {
    padding: 8px;
    &:hover {
      cursor: pointer;
      background-color: var(--list-hover-background);
    }
    &.selected {
      font-weight: 400;
      background-color: var(--list-hover-background);
    }
  }
}

dl.info-list {
  display: table;
  > div {
    display: table-row;
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
    box-sizing: border-box;

    > dt {
      width: auto;
      display: table-cell;
      padding: 4px 0;
      font-weight: 500;
    }

    > dd {
      display: block;
      min-width: 64px;
      padding: 4px 0;
      word-break: break-word;
      font-weight: 400;
    }
  }
}
