@use "@angular/material" as mat;

@use "styles/components";
@use "styles/dialog";
@use "styles/lists";
@use "styles/buttons";
@use "styles/layout";
@use "styles/status";
@use "styles/forms";
@use "styles/icons";
@use "styles/typeahead";
@use "styles/typography";
@use "styles/breadcrumbs";
@use "styles/cards";
@use "styles/nav";
@use "styles/table";

@include mat.all-component-typographies();
@include mat.core();

$breakpoints: (
  'phone': 320px,
  'tablet': 768px,
  'desktop': 1024px,
  'large': 1400px
);

html,
body {
  display: flex;
  flex-direction: row;
  margin: 0;
  width: 100%;
  height: 100%;
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.hide {
  display: none !important;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}


$my-app-light-primary: mat.define-palette(mat.$blue-gray-palette, 500, 100, 700);
$my-app-light-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);
$my-app-light-warn: mat.define-palette(mat.$red-palette);
$my-app-light-flake: mat.define-palette(mat.$yellow-palette);

$my-app-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-app-light-primary,
      accent: $my-app-light-accent,
      warn: $my-app-light-warn,
    ),
  )
);

.light-theme {
  @include mat.all-component-themes($my-app-light-theme);
  @include components.theme($my-app-light-theme);
  $primary-color: mat.get-color-from-palette($my-app-light-primary);
  $lightest-primary-color: mat.get-color-from-palette($my-app-light-primary, 0.05);
  $accent-color: mat.get-color-from-palette($my-app-light-accent);
  $success-palette: mat.define-palette(mat.$green-palette);
  $info-button-palette: mat.define-palette(mat.$blue-palette);
  $indigo-palette: mat.define-palette(mat.$indigo-palette);

  $success-color: mat.get-color-from-palette($success-palette, 500);
  $failed-color: mat.get-color-from-palette($my-app-light-warn, 500);
  $flake-color: mat.get-color-from-palette($my-app-light-flake, 900);
  $info-icon-color: mat.get-color-from-palette($info-button-palette, 300);
  $debug-icon-color: mat.get-color-from-palette($success-palette, 500);
  $cmdout-color: mat.get-color-from-palette($indigo-palette, 800);

  $nav-background-color: mat.get-color-from-palette($my-app-light-primary, 100, 0.5);

  .highlight .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($my-app-light-accent, 0.8);
    color: black;
  }

  --panel-background: #fff;
  --accent: #{$accent-color};
  --debug: #{$debug-icon-color};
  --info: #{$info-icon-color};
  --cmdout: #{$cmdout-color};
  --primary: #{$primary-color};
  --primary-light: #{$lightest-primary-color};
  --success: #{$success-color};
  --failed: #{$failed-color};
  --cancelled: #888;
  --warning: #{$failed-color};
  --flake: #{$flake-color};
  --border-color: #ddd;
  --border-radius: 4px;
  --list-hover-background: rgba(0, 0, 0, 0.05);
  --list-hover-background-opacity: 0.04;
  --nav-active-background: rgba(63,81,181,.15);
  --breadcrumb-background: #{$nav-background-color};
  --nav-active-color: blue;
  --text-color: black;

  .primary {
    color: var(--primary);
  }
}

//.toolbar {
//  margin: 8px 0 16px 0;
//}

.dark-backdrop {
  background: rgba(0, 0, 0, 0.70);
}


a.mat-mdc-tab-link:hover:not(.standard) {
  text-decoration: none;
}

.list-item {
  &:hover {
    background-color: var(--list-hover-background);
  }
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
}

app-root {
  overflow: hidden;
}

nav {
  border-top: 1px solid var(--border-color);
}

.flake-fg {
  color: var(--flake);
}


mat-tab-header {
  user-select: none;
}

.icon-and-name {
  fa-icon {
    margin-right: 8px;
  }
}

.hint {
  font-size: 12px;
  margin-top: 4px;
}
