
fa-icon.passed, fa-icon.success {
  color: var(--success);
}

fa-icon.failed {
  color: var(--failed);
}

fa-icon.running {
  color: var(--primary);
}

fa-icon.warning, mat-icon.warning {
  color: var(--warning) !important;
}

button.mat-mdc-icon-button.large-icon-button.mat-mdc-button-base {
  padding: 0;
  img {
    width: 40px;
    height: 40px;
  }
}

img.avatar, .no-avatar {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: solid 2px #aaa;
  text-align: center;
}

.no-avatar {
  opacity: 0.4;
  display: flex;
  align-content: center;
  justify-content: center;
  fa-icon svg {
    height: 28px;
    padding-top: 1px;
  }
}
