@use "@angular/material" as mat;
@import "src/styles/mixins/layout";

.nav-bar {
  @include row-centered();
  width: 100%;
  padding: 8px;

  a.logo {
    color: white;
    text-decoration: none;
    padding: 4px 8px;

    img {
      height: 24px;
    }

    border-radius: 8px;
    margin-right: 16px;

    &:hover, &.active {
      text-decoration: none;
    }
  }
}
