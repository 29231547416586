@use '@angular/material' as mat;
@import 'src/styles/mixins/layout';

section {
  margin: 8px 0;
}

.row-centered {
  @include row-centered();
  gap: 16px;
}

.row-align-top {
  @include row-align-top();
}

.row-centered-wrap {
  @include row-centered();
  flex-wrap: wrap;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-spacer {
    flex: 1 1 0;
    box-sizing: border-box;
}

.row-space-between {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.field-row {
  @include row-centered();
  gap: 16px;
}

.space-between {
  justify-content: space-between;
}

.fill-overflow {
  @include fill-overflow();
}

.page {
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: stretch flex-start;
  align-items: stretch;
  max-width: 100%;
  padding: 16px;
  overflow: hidden;

  > .main-heading {
    @include row-align-top();
    justify-content: space-between;
  }
}

.tab-contents {
  padding: 8px 0;
}

.p1 {
  padding: 8px;
}

.p2 {
  padding: 16px;
}


.m1 {
  margin: 8px;
}

.m1-vertical {
  margin: 8px 0;
}

.m2-vertical {
  margin: 16px 0;
}


.label-value-vertical {
  flex-direction: column;
  display: flex;
  label {
    opacity: 0.7;
  }
}

mat-tab-group.full-flex {
  .mat-mdc-tab-body-wrapper {
    flex: 1 0 0;
  }

  .mat-mdc-tab-body-content {
    height: 100%;
    overflow: auto;
    display: flex;
  }
}

.end-button-row {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
}

.list-and-detail-box {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  flex: 1 0 0;
  column-gap: 16px;
  justify-content: space-between;
  overflow: hidden;
  mat-selection-list {
    max-width: 30%;
    flex: 1;
    overflow: auto;
  }
  .form-wrapper {
    flex: 1;
  }
}


.tab-contents {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label-field-row {
  display: flex;
  flex-direction: row;
  gap: 16px;

  > .description {
    min-width: 300px;
    padding-top: 8px;
    label {
      font-weight: 400;
    }
    .description {
      opacity: 0.7;
    }
  }
}

.hero-page {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: stretch;
  width: 100%;
  overflow: hidden;

  .main-contents {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .hero {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}


.standard-toolbar {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}
