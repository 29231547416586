.info-item {
  display: flex;
  flex-direction: row;
  label {
    opacity: 0.7;
    margin-right: 8px;
  }
}

p {
  margin: 8px 0;
}

label {
  font-weight: 500;
  opacity: 0.8;
}
