@use '@angular/material' as mat;
@import "src/styles/variables";
@import "src/styles/mixins/layout";

.card {
  padding: 8px 0;
  margin: 8px;
  @include mat.elevation(2);
  border-radius: var(--border-radius);

  .toolbar {
    margin: 0 8px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
  }

  .card-header {
    font-weight: 500;
    h1, h2, h3 {
      margin-left: 8px;
      margin-bottom: 0;
    }
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid var(--border-color);
  }

  .card-contents {
    margin: 16px 8px 8px 8px;
  }

  .card-footer {
    margin: 8px;
  }


}
